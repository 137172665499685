<template>
  <div>
    <!-- 新增视频弹窗 -->
    <!-- autoplay="autoplay" muted -->
    <!-- <div class="video_outline" v-show="video_outline">
      <div class="video_into">
        <span class="close_vide" @click="closeVideFn">
          <img :src="videoClose" class="close_vide_img"/>
        </span>

        <video id="video1" ref="vueRef" :src="videosrc" controls="controls">

        </video>
      </div>
    </div> -->
    <!-- 新增视频弹窗 -end -->
    <!-- :height="'auto'" -->
    <el-carousel :interval="5000" >
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <!-- @click="videoFn" -->
        <!-- <div
          class="index-top color-white"
          :style="{ backgroundImage: 'url(' + item.img + ')' }"
        /> -->
        <div
          class="index-top color-white"
          :style="{ background: 'url(' + item.img + ') no-repeat center', backgroundSize:'cover' }"
        />
      </el-carousel-item>

      <!-- <el-carousel-item>
        <div
          class="index-top color-white d-flex"
          :style="{
            backgroundImage: 'url(' + banner[2] + ')',
            alignItems: 'center',
          }"
        >
          <div class="content-box pl-50">
            <h1 class="pt-20">{{ $t("home.title1") }}</h1>
            <h2 class="pb-20">{{ $t("home.title2") }}</h2>
          </div>
        </div>
      </el-carousel-item> -->
    </el-carousel>
    <div class="box-shadow bg-white">
      <div class="d-flex-center notice-bar p-20 mb-30">
        <div v-for="(item, index) in noticeList" :key="index">
          <el-link
            type="primary"
            :title="item.title"
            class="link"
            @click="$nav.push('/notice/details/' + item.id)"
            >{{ item.title }}</el-link
          >
          <span class="mx-20">/</span>
        </div>
        <div>
          <el-link type="primary" class="link" @click="$nav.push('/notice')">{{
            $t("n_more")
          }}</el-link>
        </div>
      </div>
    </div>
    <div class="content-box bg-white box-shadow mb-30">
      <div class="tabs">
        <div
          class="tab px-25 pt-20"
          :class="{ active: coin == 'USDT' }"
          @click="onTab('USDT')"
        >
          USDT {{ $t("home.trade") }}
        </div>
        <!-- <div
          class="tab px-25 pt-20"
          :class="{ active: coin == 'BTC' }"
          @click="onTab('BTC')"
        >
          BTC {{ $t("home.trade") }}
        </div>
        <div
          class="tab px-25 pt-20"
          :class="{ active: coin == 'ETH' }"
          @click="onTab('ETH')"
        >
          ETH {{ $t("home.trade") }}
        </div> -->

        <div class="search_box" style="margin-left: auto">
          <el-input
            :placeholder="$t('search_text')"
            prefix-icon="el-icon-search"
            @keyup.enter.native="searchFn"
            v-model="search_text"
          >
          </el-input>
        </div>
      </div>
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="font-14 color-gray px-20 py-15 no-m"
      >
        <el-col :span="4">{{ $t("home.pair") }}</el-col>
        <el-col :span="4">{{ $t("home.price") }}</el-col>
        <el-col :span="4">{{ $t("home.change") }}</el-col>
        <el-col :span="4">24H {{ $t("home.high") }}</el-col>
        <el-col :span="4">24H {{ $t("home.low") }}</el-col>
        <!-- <el-col :span="4">24H {{ $t("home.finished") }}</el-col> -->
        <el-col class="text-right" :span="3">{{ $t("home.deal") }}</el-col>
      </el-row>
      <el-row
        v-for="(item, index) in market"
        :key="index"
        :gutter="20"
        type="flex"
        justify="center"
        align="middle"
        class="market-body px-20 py-15 border-top"
      >
        <el-col :span="1">
          <div
            v-if="item.logo"
            class="icon icon-style-yuan icon-stars"
            :style="{ backgroundImage: 'url(' + item.logo + ')' }"
          />
          <div
            v-else
            class="icon icon-style-yuan icon-stars"
            :class="'icon-coin-' + item.pair.toLowerCase()"
          />
        </el-col>
        <el-col :span="3"
          ><span
            class="is-link color-p-h"
            @click="$nav.push('/market/' + item.pair + '/' + item.coin)"
            >{{ item.pair.toUpperCase() }} / {{ item.coin.toUpperCase() }}</span
          ></el-col
        >
        <el-col :span="4">{{ item.lasest_price }}</el-col>
        <el-col :span="4" class="d-flex-align-center">
          <el-tag
            v-if="item.change > 0"
            class="change"
            type="success"
            effect="dark"
            size="medium"
            >+{{ item.change.toFixed(2) }}%</el-tag
          >
          <el-tag
            v-else-if="item.change == 0"
            class="change"
            type="warning"
            effect="dark"
            size="medium"
            >0.00%</el-tag
          >
          <el-tag
            v-else
            class="change"
            type="danger"
            effect="dark"
            size="medium"
            >{{ item.change.toFixed(2) }}%</el-tag
          >
          <i class="el-icon-top ml-5" :class="{ 'd-none': !item.showUp }" />
          <i
            class="el-icon-bottom ml-5"
            :class="{ 'd-none': !item.showDown }"
          />
        </el-col>
        <el-col :span="4">{{ item.high }}</el-col>
        <el-col :span="4">{{ item.low }}</el-col>
        <!-- <el-col :span="4">{{ item.volume_24h }}</el-col> -->
        <el-col class="deal text-right" :span="3">
          <el-button
            type="primary"
            size="small"
            plain
            class="bg-t"
            icon="el-icon-s-data"
            @click="$nav.push('/market/' + item.pair + '/' + item.coin)"
            >{{ $t("home.trade") }}</el-button
          >
        </el-col>
      </el-row>
      <div
        v-if="market.length < 1 && marketFlag == false"
        v-loading="true"
        element-loading-background="#00000000"
        class="p-50"
      />
      <!-- 分頁 -->
      <div class="d-flex-center">
        <el-pagination
          class="pt-20 pb-20"
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page="page"
          :total="total"
          @current-change="onPage"
        />
      </div>
    </div>

    <!-- 修改布局 服务模块 -->
    <div class="content-box services bg-white box-shadow mb-30">
      <div class="content-bg py-30">
        <h2 class="text-center pb-30 color-333">
          {{ $t("home.service_title") }}
        </h2>
        <!-- <el-row :gutter="20" type="flex" justify="center" align="top">
          <el-col :span="6">
            <el-card class="item text-center">
              <div>
                <img
                  src="@/assets/index/index-icon-service1.gif"
                  alt="service icon"
                />
              </div>
              <h4>{{ $t("home.service1.title") }}</h4>
              <p>{{ $t("home.service1.des1") }}</p>
              <p>{{ $t("home.service1.des2") }}</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="item text-center">
              <div>
                <img
                  src="@/assets/index/index-icon-service2.gif"
                  alt="service icon"
                />
              </div>
              <h4>{{ $t("home.service2.title") }}</h4>
              <p>{{ $t("home.service2.des1") }}</p>
              <p>{{ $t("home.service2.des2") }}</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="item text-center">
              <div>
                <img
                  src="@/assets/index/index-icon-service3.gif"
                  alt="service icon"
                />
              </div>
              <h4>{{ $t("home.service3.title") }}</h4>
              <p>{{ $t("home.service3.des1") }}</p>
              <p>{{ $t("home.service3.des2") }}</p>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card class="item text-center">
              <div>
                <img
                  src="@/assets/index/index-icon-service4.gif"
                  alt="service icon"
                />
              </div>
              <h4>{{ $t("home.service4.title") }}</h4>
              <p>{{ $t("home.service4.des1") }}</p>
              <p>{{ $t("home.service4.des2") }}</p>
            </el-card>
          </el-col>
        </el-row> -->

        <div class="new_service">
          <div class="new_service_item">
            <div class="item text-center">
              <div>
                <img src="@/assets/index/icon_1.png" alt="service icon" />
              </div>
              <h4>{{ $t("home.service1.title") }}</h4>
              <p>{{ $t("home.service1.des1") }}</p>
              <p>{{ $t("home.service1.des2") }}</p>
            </div>
          </div>
          <div class="new_service_item">
            <div class="item text-center">
              <div>
                <img src="@/assets/index/icon_2.png" alt="service icon" />
              </div>
              <h4>{{ $t("home.service2.title") }}</h4>
              <p>{{ $t("home.service2.des1") }}</p>
              <p>{{ $t("home.service2.des2") }}</p>
            </div>
          </div>
          <div class="new_service_item">
            <div class="item text-center">
              <div>
                <img src="@/assets/index/icon_3.png" alt="service icon" />
              </div>
              <h4>{{ $t("home.service3.title") }}</h4>
              <p>{{ $t("home.service3.des1") }}</p>
              <p>{{ $t("home.service3.des2") }}</p>
            </div>
          </div>
          <div class="new_service_item">
            <div class="item text-center">
              <div>
                <img src="@/assets/index/icon_4.png" alt="service icon" />
              </div>
              <h4>{{ $t("home.service4.title") }}</h4>
              <p>{{ $t("home.service4.des1") }}</p>
              <p>{{ $t("home.service4.des2") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改布局 -end -->

    <!-- 新增新手指南 -->
    <div class="content-box guide bg-white box-shadow mb-30">
      <div class="content-bg py-30">
        <h2 class="text-center pb-30 color-333">
          {{ $t("home.guide_title") }}
        </h2>
        <div class="guide_box">
          <div class="guide_box_content">
            <div class="guide_box_item guide_box_item_active">
              <div class="guide_box_item_top">
                <div class="guide_money_zoom">
                  <div class="guide_money">
                    <span class="guide_money_num">10</span>
                    <span class="guide_money_unit">USDT</span>
                  </div>
                  <div class="guide_money_text">
                    {{ $t("index.guide_box.item1_text") }}
                  </div>
                </div>
                <div class="guide_text_zoom">
                  <div class="guide_time">
                    <span class="guide_time_num">{{ curentTime.day }}</span>
                    <span class="guide_time_cli">:</span>
                    <span class="guide_time_num">{{ curentTime.hh }}</span>
                    <span class="guide_time_cli">:</span>
                    <span class="guide_time_num">{{ curentTime.mm }}</span>
                    <span class="guide_time_cli">:</span>
                    <span class="guide_time_num">{{ curentTime.ss }}</span>
                  </div>
                  <div class="guide_time_button" @click="goRegister">
                    {{ $t("index.guide_box.item1") }}
                  </div>
                </div>
              </div>
              <div class="guide_box_item_bottom">
                <div class="poss poss1 poss_active"></div>
                <div class="poss poss2"></div>
                <div class="poss_text_box poss_text_box_active">
                  <span class="poss_point"></span>
                  <span class="poss_text">{{
                    $t("index.guide_box.item1")
                  }}</span>
                </div>
              </div>
            </div>

            <div class="guide_box_item">
              <div class="guide_box_item_top">
                <div class="guide_money_zoom">
                  <div class="guide_money">
                    <span class="guide_money_num">20</span>
                    <span class="guide_money_unit">USDT</span>
                  </div>
                </div>
                <div class="guide_text_zoom">
                  <div class="guide_text">
                    {{ $t("index.guide_box.item2_text") }}
                  </div>
                </div>
              </div>
              <div class="guide_box_item_bottom">
                <div class="poss poss1"></div>
                <div class="poss poss2"></div>
                <div class="poss_text_box">
                  <span class="poss_point"></span>
                  <span class="poss_text">{{
                    $t("index.guide_box.item2")
                  }}</span>
                </div>
              </div>
            </div>

            <div class="guide_box_item">
              <div class="guide_box_item_top">
                <div class="guide_money_zoom">
                  <div class="guide_money">
                    <span class="guide_money_num">50</span>
                    <span class="guide_money_unit">USDT</span>
                  </div>
                </div>
                <div class="guide_text_zoom">
                  <div class="guide_text">
                    {{ $t("index.guide_box.item3_text") }}
                  </div>
                </div>
              </div>
              <div class="guide_box_item_bottom">
                <div class="poss poss1"></div>
                <div class="poss poss2"></div>
                <div class="poss_text_box">
                  <span class="poss_point"></span>
                  <span class="poss_text">{{
                    $t("index.guide_box.item3")
                  }}</span>
                </div>
              </div>
            </div>

            <div class="guide_box_item">
              <div class="guide_box_item_top">
                <div class="guide_money_zoom">
                  <div class="guide_money">
                    <span class="guide_money_num">100</span>
                    <span class="guide_money_unit">USDT</span>
                  </div>
                </div>
                <div class="guide_text_zoom">
                  <div class="guide_text">
                    {{ $t("index.guide_box.item4_text") }}
                  </div>
                </div>
              </div>
              <div class="guide_box_item_bottom">
                <div class="poss poss1"></div>
                <div class="poss poss2"></div>
                <div class="poss_text_box">
                  <span class="poss_point"></span>
                  <span class="poss_text">{{
                    $t("index.guide_box.item4")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增新手指南 -end -->
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";

import videoClose from "@/assets/index/close_video.png";
// import addBanner from "@/assets/index/xinbanner_02.jpg";
// import addBanner from "@/assets/index/11122_02.jpg";

export default {
  data() {
    return {
      // banner: this.$t("home.banner"),
      coinLogo,
      videoClose,
      // addBanner,
      coin: this.$store.state.marketCoin,
      noticeList: [],
      marketOld: null,
      market: [],
      showUp: false,
      showDown: false,
      loading: false,
      timer: null,
      dataVersion: 0,

      //新增
      marketLength: 0,
      socket1: null,

      curentTime: {
        year: "0000",
        month: "00",
        day: "00",
        hh: "00",
        mm: "00",
        ss: "00",
      },
      timeGet: null,

      bannerList: [],

      //搜索
      search_text: "",

      //分頁
      pageSize: 10,
      page: 1,
      total: 0,
      marketFlag: false,

      // videosrc:'https://file.bittrillion.com/mp4/coinbull.mp4',
      video_outline: false,
    };
  },
  watch: {
    coin: function () {
      this.market = [];
      this.page = 1;
      this.marketFlag = false;
      this.marketLength = 0;
      if (["BTC", "ETH", "USDT"].indexOf(this.coin) < 0) return;
      this.$store.commit("marketCoin", this.coin);
      this.tabChange(true);
    },
  },
  created: function () {
    this.fatchNotice();
    this.tabChange(true);
    // this.timer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   if (["BTC", "ETH", "USDT"].indexOf(this.coin) < 0) return;
    //   this.tabChange(false);
    // }, 2000);

    this.getBanner();

    this.timeGet = setInterval(() => {
      // this.getCurentTime()
      this.getDistanceTime("2023-05-19 12:00:00");
    }, 1000);
  },
  beforeDestroy: function () {
    window.clearInterval(this.timer);
    if (this.timeGet) {
      clearInterval(this.timeGet);
    }
  },
  destroyed: function () {
    this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    // 搜索
    searchFn() {
      this.market = [];
      this.marketLength = 0;
      this.marketFlag = false;
      this.page = 1;
      this.tabChange();
    },

    //分頁
    onPage(e) {
      this.marketFlag = false;
      this.page = e;
      this.market = [];
      this.marketLength = 0;
      this.tabChange();
    },

    fatchNotice: async function () {
      // const { data } = await this.$request.post("v1/notice");
      const { data } = await this.$request.post("v1/notice");
      // this.noticeList = data;
      this.noticeList = data.data;
    },
    onTab: function (active) {
      this.coin = active;
    },

    //获取banner
    getBanner: async function () {
      var e = this.$store.state.locale ? this.$store.state.locale : "zh-TW";
      var num = "";
      if (e == "zh-TW") {
        num = 1;
      } else if (e == "en-US") {
        num = 2;
      } else if (e == "Latin") {
        num = 3;
      } else if (e == "Thailand") {
        num = 7;
      } else if (e == "Korea") {
        num = 8;
      } else if (e == "Japan") {
        num = 9;
      }
      const { data } = await this.$request.post("v1/banner", { type: num });
      // console.log(data)
      // this.bannerList.push({img:addBanner})
      // this.bannerList = this.bannerList.concat(data)
      // console.log(this.bannerList)
      this.bannerList = data;
    },

    //倒計時
    getDistanceTime(time) {
      var endTime = new Date(
        Date.parse(time.replace(/-/g, "/"))
      ); /*replace将时间字符串中所有的'-'替换成'/',parse将时间格式的字符串转换成毫秒*/
      var nowTime = new Date();
      var distance =
        endTime.getTime() -
        nowTime.getTime(); /*getTime把一个date对象转换成毫秒*/

      var day = 0;
      var hour = 0;
      var minute = 0;
      var second = 0;

      if (distance >= 0) {
        day = Math.floor(distance / 1000 / 60 / 60 / 24);
        hour = Math.floor((distance / 1000 / 60 / 60) % 24);
        minute = Math.floor((distance / 1000 / 60) % 60);
        second = Math.floor((distance / 1000) % 60);
      } else {
        day = 0;
        hour = 0;
        minute = 0;
        second = 0;
      }

      if (day < 10) {
        day = "0" + day;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      this.curentTime.day = day;
      this.curentTime.hh = hour;
      this.curentTime.mm = minute;
      this.curentTime.ss = second;
    },

    //獲取當前時間
    getCurentTime() {
      var now = new Date();

      var year = now.getFullYear(); //年
      var month = now.getMonth() + 1; //月
      var day = now.getDate(); //日

      var hh = now.getHours(); //时
      var mm = now.getMinutes(); //分
      var ss = now.getSeconds(); //分

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      if (hh < 10) {
        hh = "0" + hh;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      if (ss < 10) {
        ss = "0" + ss;
      }

      this.curentTime.year = year;
      this.curentTime.month = month;
      this.curentTime.day = day;
      this.curentTime.hh = hh;
      this.curentTime.mm = mm;
      this.curentTime.ss = ss;
    },

    // tabChange: function (ignoreLoadingStatus = false) {
    //     if (this.loading && !ignoreLoadingStatus) return
    //     this.dataVersion++
    //     let version = Number(this.dataVersion)
    //     this.loading = true
    //     this.$requestNotState.get('market_detail/'+this.coin).then(({ data }) => {
    //         this.loading = false
    //         if(version != this.dataVersion) {
    //             return
    //         }
    //         if(this.market.length > 0){
    //             for(const index in data){
    //                 if(this.market[index]){
    //                     if(data[index].lasest_price > this.market[index].lasest_price){
    //                         data[index].showUp = true
    //                     }else if(data[index].lasest_price < this.market[index].lasest_price){
    //                         data[index].showDown = true
    //                     }
    //                 }
    //             }
    //         }
    //         this.market = data
    //         window.setTimeout(() => {
    //             for(const item of this.market){
    //                 item.showUp = false
    //                 item.showDown = false
    //             }
    //         }, 600)
    //     }).catch(() => {
    //         this.loading = false
    //     })
    // },

    // fetchMarket: function () {
    //   if (this.marketLoading) return;
    //   let url = "v1/symbol/getList";
    //   let postData = {
    //     coin: this.marketCoin,
    //   };
    //   this.$request
    //     .post(url, postData)
    //     .then((res) => {
    //       this.market = res.data;
    //       this.marketLength = res.data.length;

    //       let arr = {};
    //       if (res.data) {
    //         res.data.forEach(function (item, index) {
    //           arr[item.pair + "" + item.coin] = item;
    //         });
    //       }
    //       this.market = arr;
    //       this.marketLoading = false;

    //       //wss實時請求
    //       this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
    //         query: {},
    //         transports: ["websocket", "polling"],
    //         timeout: 5000,
    //         // pingTimeout: 20000,
    //         // pingInterval: 25000,
    //       });

    //       this.socket1.on("connect_error", (reason) => {
    //         console.log(reason);
    //       });

    //       this.socket1.on("connect", (socket) => {
    //         // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
    //         console.log("ws 已连接");

    //         //全部报价
    //         this.socket1.emit("symbol_price_list");
    //         this.socket1.on("symbol_price_list", (res) => {
    //           this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin]
    //             ? (this.market[
    //                 JSON.parse(res).pair + "" + JSON.parse(res).coin
    //               ] = JSON.parse(res))
    //             : "";
    //           this.market = JSON.parse(JSON.stringify(this.market));
    //         });
    //       });

    //       this.socket1.on("disconnect", (reason) => {
    //         console.log(reason);
    //       });

    //       this.socket1.on("error", (msg) => {
    //         console.log("ws error", msg);
    //       });
    //     })
    //     .catch((e) => {
    //       this.marketLoading = false;
    //     });
    // },

    tabChange: function (ignoreLoadingStatus = false) {
      this.loading = true;
      this.$requestNotState
        .post("v1/symbol/getList", {
          coin: this.coin.toLowerCase(),
          pair: this.search_text,
          pageSize: this.pageSize,
          page: this.page,
        })
        .then(({ data }) => {
          this.marketFlag = true;
          this.loading = false;
          this.market = data.list;
          this.marketLength = data.list.length;
          this.total = data.total;

          let arr = {};
          if (data.list) {
            data.list.forEach(function (item, index) {
              item.showUp = false;
              item.showDown = false;
              arr[item.pair + "" + item.coin] = item;
            });
          }
          this.market = arr;

          if (this.socket1) {
            this.socket1.close();
          }
          //wss實時請求
          // this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
          this.socket1 = this.$io(this.$wsn + "/symbol", {
            query: {},
            transports: ["websocket", "polling"],
            timeout: 5000,
            // pingTimeout: 20000,
            // pingInterval: 25000,
          });

          this.socket1.on("connect_error", (reason) => {
            console.log(reason);
          });

          this.socket1.on("connect", (socket) => {
            // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
            console.log("ws 已连接");

            //全部报价
            this.socket1.emit("symbol_price_list");
            this.socket1.on("symbol_price_list", (res) => {
              var new_arr = {};
              new_arr = JSON.parse(res);
              //操作箭头
              new_arr.showUp = false;
              new_arr.showDown = false;
              if (this.market[new_arr.pair + "" + new_arr.coin]) {
                if (
                  new_arr.lasest_price >
                  this.market[new_arr.pair + "" + new_arr.coin].lasest_price
                ) {
                  new_arr.showUp = true;
                  new_arr.showDown = false;
                } else if (
                  new_arr.lasest_price <
                  this.market[new_arr.pair + "" + new_arr.coin].lasest_price
                ) {
                  new_arr.showDown = true;
                  new_arr.showUp = false;
                } else {
                  new_arr.showUp = false;
                  new_arr.showUp = false;
                }
              }

              this.market[new_arr.pair + "" + new_arr.coin]
                ? (this.market[new_arr.pair + "" + new_arr.coin] = new_arr)
                : "";
              this.market = JSON.parse(JSON.stringify(this.market));
            });
          });

          //   if (version != this.dataVersion) {
          //     return;
          //   }
          //   if (this.market.length > 0) {
          //     for (const index in data) {
          //       if (this.market[index]) {
          //         if (
          //           data[index].lasest_price > this.market[index].lasest_price
          //         ) {
          //           data[index].showUp = true;
          //         } else if (
          //           data[index].lasest_price < this.market[index].lasest_price
          //         ) {
          //           data[index].showDown = true;
          //         }
          //       }
          //     }
          //   }
          //   this.market = data;

          //   window.setTimeout(() => {
          //     for (const item of this.market) {
          //       item.showUp = false;
          //       item.showDown = false;
          //     }
          //   }, 600);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onClick: function (item) {
      this.$nav.push("/trade/index/" + item.pair + "/" + item.coin);
    },

    goRegister: function () {
      this.$nav.push("/user/register");
    },

    // //打开視頻
    // videoFn(){
    //   this.video_outline = true
    //   this.$refs.vueRef.play();//播放
    // },
    // //關閉視頻
    // closeVideFn(){
    //   this.video_outline = false
    //   this.$refs.vueRef.pause();//播放
    // },
  },
};
</script>
<style scoped>
.index-top {
  /* background-image: url(~@/assets/index/top-bg.png); */
  height: 300px;
  background-repeat: no-repeat;
  background-size: auto 460px;
  background-position: center;
}
.icon-style-yuan {
  border-radius: 50%;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;
  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}
.market-body {
  .change {
    font-size: 14px !important;
    width: 80px;
    text-align: center !important;
  }
}
.services {
  .item {
    img {
      width: 100px;
      height: 100px;
    }
    p {
      font-size: 14px;
    }
  }
}

// 新服务模块
.new_service {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.new_service_item {
  width: 280px;
  padding: 15px;
  box-sizing: border-box;
}
.new_service .new_service_item p {
  color: #666;
  font-size: 13px;
  line-height: 24px;
  margin: 0;
}
.new_service .new_service_item h4 {
  font-weight: normal;
  font-size: 18px;
  margin: 25px 0 10px;
}
.market-body {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-m {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

//新手指南
.guide_box_content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.guide_box_item {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.guide_box_item_top {
  width: 220px;
  border: 1px solid rgb(242, 246, 252);
  box-sizing: border-box;
  background: rgb(242, 246, 252);
  border-radius: 5px;
  padding: 20px 10px;
}
.guide_money_zoom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.guide_money {
  width: 50px;
  height: 50px;
  background: rgb(255, 232, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.guide_money_unit {
  font-size: 12px;
}
.guide_money_num {
  font-size: 16px;
}
.guide_money_text {
  font-size: 14px;
  margin-left: 10px;
}
.guide_text_zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.guide_time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}
.guide_time_num {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: rgb(242, 246, 252);
}
.guide_time_button {
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  padding: 0 10px;
  background: #409eff;
  color: #fff;
  margin-left: 20px;
  border-radius: 3px;
}
.guide_time_cli {
  margin: 0 3px;
}
.guide_text {
  font-size: 14px;
  line-height: 24px;
}
.guide_box_item_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}
.poss {
  width: 50%;
  height: 2px;
  background: rgb(204, 210, 218);
}
.poss_active {
  background: rgb(28, 126, 255);
}
.poss_text_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.poss_point {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgb(204, 210, 218);
  border: 2px solid rgb(204, 210, 218);
  box-sizing: border-box;
  margin-bottom: 10px;
}
.poss_text_box_active .poss_point {
  border: 2px solid rgb(28, 126, 255);
  background: #fff;
}
.poss_text {
  font-size: 16px;
  color: #999;
  white-space: nowrap;
  display: block;
}
.poss_text_box_active .poss_text {
  color: rgb(28, 126, 255);
  font-weight: bold;
}
.poss_text_box {
  position: absolute;
  left: 50%;
  top: -5px;
  transform: translatex(-50%);
}
.guide .content-bg {
  padding-bottom: 80px;
}
.guide_box_item_active .guide_box_item_top {
  background: #fff;
  border-color: rgb(28, 126, 255);
}

.search_box {
  padding: 0 20px;
  box-sizing: border-box;
}

// 新增
.guide_money {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
}
.guide_box_item_top {
  min-height: 165px;
}
.guide_box_item_active .guide_money_text {
  max-width: 120px;
}
.guide_text {
  text-align: center;
}

// 新增視頻
.video_outline {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_into {
  width: 700px;
  height: 400px;
}
.video_into video {
  width: 100%;
  height: 100%;
}
.video_into {
  position: relative;
}
.close_vide {
  width: 40px;
  height: 40px;
  position: absolute;
  right: -30px;
  top: -40px;
  cursor: pointer;
}
.close_vide_img {
  width: 40px;
  height: 40px;
}
.index-top {
  cursor: pointer;
}
</style>